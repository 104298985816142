import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/layout"

const MentionsLegales: React.FC = () => (
  <Layout>
    <Helmet title="Mentions légales" />
    <div className="container">
      <div className="row mt-3">
        <div className="main-col">
          <h1>Mentions légales</h1>
          <h2>Comité Régional de Tir à l&#39;Arc du Grand Est</h2>
          <p>Organe déconcentré de la Fédération Française de Tir à l&#39;Arc (FFTA)</p>
          <p>
            Association de loi 1901
            <br />
            Enregistrée auprès de la Préfecture de Meurthe-et-Moselle sous le n°W543009328
          </p>

          <p>
            Siret : 824.012.058.00015
            <br />
            APE : 9312Z
          </p>

          <p>
            Siège social :
            <br />
            Maison régionale des sports
            <br />
            13 rue Jean Moulin - CS 70001
            <br />
            54510 Tomblaine
          </p>

          <p>
            Contact : <a href="mailto:contact@tiralarc-grand-est.fr">contact@tiralarc-grand-est.fr</a>
          </p>

          <p>
            Responsable légal : Matthieu FRATH (<a href="mailto:president@tiralarc-grand-est.fr">president@tiralarc-grand-est.fr</a>)
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default MentionsLegales
